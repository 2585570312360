import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "common-h1" }
const _hoisted_2 = { class: "orders" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "orders__el-left" }
const _hoisted_5 = { class: "orders__el-left-title" }
const _hoisted_6 = { class: "orders__el-left-date" }
const _hoisted_7 = { class: "orders__el-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.title), 1),
    (_ctx.orders?.length)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders, (order) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["orders__el", { 'orders__el--no-active': order.disabled }]),
                key: order.id,
                onClick: ($event: any) => (_ctx.$router.push('/order/' + order.id))
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, "Заказ № " + _toDisplayString(order.id), 1),
                  _createElementVNode("div", _hoisted_6, _toDisplayString(new Date(order.created).toLocaleString()), 1)
                ]),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getLocalizeState(order.state)), 1)
              ], 10, _hoisted_3))
            }), 128))
          ]),
          _createVNode(_component_el_pagination, {
            "current-page": _ctx.pageNumber,
            "onUpdate:current-page": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pageNumber) = $event)),
            background: "",
            layout: "prev, pager, next",
            total: 1000,
            "page-count": _ctx.pageCount,
            onCurrentChange: _ctx.changedPage
          }, null, 8, ["current-page", "page-count", "onCurrentChange"])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}