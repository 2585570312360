
import { defineComponent, reactive, ref, computed, onBeforeMount } from 'vue'
import axios from '@/http'
import { enumOrdersState, localizeOrderState } from '@/constains'
import { IOrderWithDisabled, IResListWrap } from '@/interfaces'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
	name: 'OrderList',

	props: {
		orderApi: {
			type: String,
			default: () => '/order/full-list'
		},

		title: {
			type: String,
			default: () => 'Все заказы'
		}
	},

	setup(props) {
		const pageNumber = ref<number>(1)

		const route = useRoute()
		const router = useRouter()

		let orders = reactive<Array<IOrderWithDisabled>>([])
		let count = ref<number>(0)
		let countLoad = 10
		const pagerCount = 3
		const pageCount = computed<number>(() => Math.ceil(count.value / countLoad))

		onBeforeMount(() => {
			if (Number.isInteger(Number(route.query.page))) pageNumber.value = Number(route.query.page) || 1
			else router.push('?page=' + 1)

			changePage(pageNumber.value)
		})

		function changedPage(e: number) {
			router.push('?page=' + e)

			changePage(pageNumber.value)
		}

		async function getLotList(page: number, limit = countLoad): Promise<IResListWrap<IOrderWithDisabled>> {
			let res = null

			try {
				res = (await axios.post(props.orderApi, { page: page - 1, limit })).data
			} catch (e) {}

			return res
		}

		async function changePage(page: number) {
			const resData = await getLotList(page)

			if (resData?.rows) {
				count.value = resData.count
				orders.splice(0, orders.length, ...resData.rows)
			}
		}

		function getLocalizeState(state: enumOrdersState) {
			const key = enumOrdersState[state] as keyof typeof enumOrdersState

			return localizeOrderState[key]
		}

		return {
			pageCount,
			orders,
			pageNumber,
			pagerCount,

			changedPage,
			getLocalizeState
		}
	}
})
